@use '@cloudscape-design/design-tokens/index' as awsui;

* {
  box-sizing: border-box;
}

html {
  font-family: awsui.$font-family-base;
  font-size: 10px;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  padding: 0;
}

body {
  margin: 0;
  font-family: awsui.$font-family-base;
  font-size: 14px;
  background-color: awsui.$color-background-layout-main;
  color: awsui.$color-text-body-default;
  line-height: calc(20 / 14);
  height: 100%;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 28px;
  font-weight: 200;
}

h2 {
  font-size: 18px;
  font-weight: 300;
}

h3 {
  font-size: 24px;
  font-weight: 500;
}

h4 {
  font-size: 18px;
  font-weight: 500;
}

h1,
h2,
h3,
h4 {
  line-height: 1.1;
  margin-top: 20px;
  margin-bottom: 10px;
}

p {
  margin: 0 0 10px;
}

button,
input {
  font-family: inherit;
  font-size: inherit;
}

button {
  appearance: auto;
  -webkit-appearance: button;
  font-size: 16px;
  line-height: inherit;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgb(0 0 0 / 50%);
  box-shadow: 0 0 1px rgb(255 255 255 / 50%);
  -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}
