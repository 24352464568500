.menu-hover {
  z-index: 11;
  position: absolute;
  width: 531px;
  height: 2px;
  background-color: gray;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}

.menu-container {
  z-index: 15;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #efefef;
  border-color: #efefef;
  box-shadow: 1px 1px 1px 1px #9d9d9d;
  border-radius: 0 0 8px 8px;
  padding: 8px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu-container button {
  border: none;
}

.menu-logo {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.menu-img {
  margin-left: 15px;
  height: 16px;
}

.menu-item {
  margin-left: 8px;
  color: #58595b;
  cursor: pointer;
  text-decoration: none;
}

.ws-menu-fade.hide {
  transition: 1s linear all;
  opacity: 0;
}

.ws-menu-fade.show {
  transition: 1.5s linear all;
  opacity: 1;
}

button.menu-item:hover {
  color: #ce453d;
  cursor: pointer;
  image-rendering: -moz-crisp-edges;
}

.pinned {
  filter: invert(12%) sepia(42%) saturate(1229%) hue-rotate(163deg)
    brightness(93%) contrast(97%);
}
