.error-title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 18px;
}

.error-detail {
  margin: 0 0 10px;
}

.error-code {
  font-size: smaller;
  color: #767676;
  text-align: center;
  margin: 0 0 10px;
}
