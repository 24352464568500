.video-container {
  overflow: auto;
  background-color: #fff;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.stx-video {
  position: absolute;
}

.stx-canvas {
  position: absolute;
  display: none;
  transform: translate(-50%, -50%);
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
}
