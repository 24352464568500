.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  width: 270px;
}

.options-container {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.form-wrapper {
  width: 270px;
  padding: 1rem 0;
}

.checkbox-wrapper {
  width: 270px;
  float: left;
}

.btn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.main-btn {
  display: inline-block;
  width: 220px;
  text-align: center;
}

.other-btn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
