.health-check-alert {
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 12px;
  width: 100%;
  line-height: 1;
  margin: 0;
}

.health-check-alert p {
  font-size: 12px;
  margin: 0;
}
