@use '@cloudscape-design/design-tokens/index' as awsui;

.overlay-play {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
  z-index: 999;
}

.logo-play {
  height: 45px;
  z-index: 999;
}
