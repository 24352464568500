.container {
  margin: 2.4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
}

.support {
  font-size: 14px;
}
